var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-dialog',{attrs:{"id":"fileView","overlay-color":"grey darken-1"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.documentFileType)?_c('v-card',{staticClass:"image-container"},[_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_c('i',{staticClass:"mdi mdi-close"}),_vm._v(" "+_vm._s(_vm.$t('actions.close'))+" ")])],1),(
          _vm.documentFileType.contentType == 'image/png' ||
          _vm.documentFileType.contentType == 'image/jpeg'
        )?_c('v-img',{staticClass:"pad-media",staticStyle:{"height":"80%"},attrs:{"lazy-src":"img/logo_cmmu.9ca8d5e0.png","src":_vm.documentFile,"data-pad-media":_vm.documentName}}):_vm._e(),(_vm.documentFileType.contentType == 'application/pdf')?_c('pdf',{staticClass:"pad-media",staticStyle:{"height":"80%"},attrs:{"src":_vm.documentFile,"data-pad-media":_vm.documentName}}):_vm._e()],1):_vm._e()],1),(_vm.documentFileType && !_vm.loading)?_c('v-card',{staticClass:"text-center",on:{"click":function($event){_vm.dialog = true}}},[(
        _vm.documentFileType.contentType == 'image/png' ||
        _vm.documentFileType.contentType == 'image/jpeg'
      )?_c('v-img',{staticClass:"pad-media",attrs:{"lazy-src":"img/logo_cmmu.9ca8d5e0.png","src":_vm.documentFile,"data-pad-media":_vm.documentName},on:{"click":function($event){_vm.dialog = true}}}):_vm._e(),(_vm.documentFileType.contentType == 'application/pdf')?_c('pdf',{staticClass:"pad-media",attrs:{"src":_vm.documentFile,"data-pad-media":_vm.documentName},on:{"click":function($event){_vm.dialog = true}}}):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"info","href":_vm.documentFile,"download":"","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)],1)],1):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }